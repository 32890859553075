const teamData = [
  {
    id: 1,
    name: "Wally Adeleye",
    image: require("../images/WALE.jpg"),
    email: "wallya@vertevilleenergy.com",
    position: "Managing Director",
    info1:
      "Mr. Adewale Mofoluwasho Adeleye holds a B.SC Accounting from University of Baltimore, United State of America, a professional qualified Accountant- CPA, CGMA and also Master of Business Administration from Instituto de Empresa Madrid, Spain in June 2011.",
    info2:
      "He started his working career with Orion Power Holdings Limited as a Contract Administrator in September 2000 and moved to Arthur Bell and Associates LLP as Staff Accountant",
    info3:
      "Wally joined Deloitte & Touche, LLP Baltimore as Senior Consultant, Enterprises Risk Services and latter moved to AES Corporation, United State, Arlington as Senior Manager (Africa) as an Internal Audit and Sarbanes Oxley in March 2007. He was posted to Africa to manage Internal Control section of the companies in Cameroon and Nigeria.",
    info4:
      "Wally was promoted to the position of Director of Finance and Procurement in AES Nigeria Barge Ltd in September 2011, and promoted to Country and Business Manager in December 2012. He is also the Managing Director of Cyrex Energy Limited. He is a board member of American Business Council of Nigeria and Board Member of the Initial Stakeholders Advisory Panel of the Nigeria Electricity Service Industry, chaired by the Regulator (NERC).",
  },
  {
    id: 2,
    name: "Rufo Jr. Manalo",
    image: require("../images/Rufo.jpg"),
    email: "info@vertevilleenergy.com",
    position: "Operation and Maintenance Manager",
    info1:
      "Rufo Manalo Jr is a Control and Instrumentation Engineer with over 30 years in operation and maintenance works experienced in the Oil and Gas refinery and the power plant production in the Philippines, Middle East and Africa.",
    info2:
      "He started to work as Instrument maintenance technician in Philippines Coal Fired Thermal Power Plant in 3 years. Then, Rufo joined Southern Energy Philippines, Frame 6 GE Gas turbine with Mark IV control system as Control &amp; Instrument Supervisor. During his work in Philippines Southern Energy in 7 years, he was assigned in different locations in Philippines.",
    info3:
      "At year 1999 December, Rufo moved and joined to AES Nigeria Power/formerly owned by ENRON Power. The 1 st IPP power plant project in Nigeria. Rufo, joined AES Nigeria Power Barge as Power plant engineer to trained and familiarized the Nigerian Engineers the system control operation including the operation team.",
    info4:
      "After 10 years working in AES Nigeria, Rufo moved and joined to AES Qatar Power plant (RasLaffan Power Company) with the same position. He worked in Qatar for 7 years before he moved and joined to Abu Dhabi National Oil Company (ADNOC).",
    info5:
      "Rufo joined Verteville Energy Company Limited with his in-depth knowledge of Thermal power plant, Combined Cycle Gas turbine power plant, Frame 6 and Frame 9 Gas turbine, Siemens Gas turbine and Desalination plant unit. He has a good technical knowledge and skills of different types of control system such as Mark IV &amp; V Speedtronic Controls, Infi 90 ABB DCS, Yokogawa DCS controls, Allen Bradley PLC controls.",

    info6:
      "Prior to joining Verteville Energy, Rufo was in Abu Dhabi National Oil Company (ADNOC), UAE from 2016 to 2021 as a Control &amp; Instrument Supervisor and assigned in Process area.",
    info7:
      "Rufo is a graduate of Electronics &amp; Instrumentation Engineering and he is a member of the school student scholarship program.",
  },

  {
    id: 3,
    name: "Ramon Adelani",
    image: require("../images/ramon.jpg"),
    email: "info@vertevilleenergy.com",
    position: "Chief Financial Officer",
    info1:
      "Mr. Adelani is the Chief Financial Officer, he has over fourteen years’ experience in the energy industry, rising through the ranks from the Oil and Gas Servicing Company to the Electricity Generation Company.",
    info2:
      "Prior to joining Verteville Energy, Mr. Adelani worked with Cyrex Energy Limited and AES Nigeria Barge Ltd, the first IPP Company in Nigeria on the 1st of November 2006 as a Management Accountant. In AES, he rose through the ranks to become the Director of Finance and Procurement as well as Directors on the Board of AES Nigeria Barge Ltd and AES Nigeria Barge Operations Ltd.",
    info3:
      "Mr. Adelani held positions in the finance department of United Geophysical (Nigeria) Ltd, focusing on managing the Accounts of the various field projects in the Niger Delta Region.",
    info4:
      "Mr. Adelani graduated from Obafemi Awolowo University, Ile-Ife with a B.Sc. in Accounting in 1992 and an MBA in Finance from the same University in 2005. He is also a qualified Chartered Accountant with the Institute of Chartered Accountants of Nigeria, an Associate member of Nigerian Institute of Management and a Fellow of the Institute of Credit Administration.",
  },

  {
    id: 4,
    name: "Tunde Iyanda",
    image: require("../images/Tunde.jpg"),
    email: "info@vertevilleenergy.com",
    position: "Technical and Operations Manager",
    info1:
      "Engr. Tunde Iyanda (MNSE, COREN) is an experienced rotating, static, and reciprocating equipment with over thirty years experience.",
    info2:
      "His expertise cut across maintenance of equipment used in Power and energy, oil  and gas, manufacturing and production industries, rising through the ranks from mechanical supervisor, head of Department, manager and presently Technical and Operations Manager in Verteville Energy Company Limited.",
    info3:
      "Prior to joining Verteville Energy, he was Mechanical Team Leader in Cyrex Energy Lagos 2015. He was also operations and maintenance (O and M) engineer and Assistance Team Leader at AES Nigeria barge Operations, the first IPP Company in Nigeria in 2015. He serves as Production Manager and Engineer in charge at Kano Sugar Processing Company Kano in 2007 and Nigeria Paper Mill Limited Jebba in 2000 respectively.",
    info4:
      "Tunde Iyanda is a graduate of Mechanical Engineering from Kwara State Polytechnic Ilorin, HND Mechanical Engineering (Plant bias) and Post Graduate Diploma (PGD) in Mechanical engineering from Bayero university Kano. He also has professional and management trainings such as Mechanical Engineering practice, Gas Turbine Maintenance Management, Reliability Center Maintenance, Emergency Response, Rigging, lifting and scaffolding training etc. ",
  },
  {
    id: 5,
    name: "Raul G. Estrada",
    image: require("../images/Raul.jpg"),
    email: "info@vertevilleenergy.com",
    position: "Team Lead - Electrical",
    info1:
      "He holds a Degree in Bachelors of Science in Electrical Engineering from Saint Joseph Institute of Technology, Butuan City, Philippines. Mr. Estrada has more than thirty years in Electrical work experiences on Power generating plants which include 11 Frame 6 GE Gas Turbine 30MW generators and 3 GE Steam Turbine with 55MW Generator each on CFBC Coal Fired Power Plant in the Philippines and Africa.",
    info2:
      "He joined Southern Energy to Mirant Energy Philippines on March 1993 and rose up position to Electrical Specialist, then to ENRON Nigeria to AES Nigeria Barge Limited on December 1999 to November 2014 as Electrical Maintenance Engineer and rose to Operation and Maintenance Engineer. He worked as Electrical expat at Cyrex Energy Nigeria from November 2014 to December 2015. He later joined Minergy Power Corporation Philippine as Electrical Supervisor December 2015 to July 2022. He is currently the Electrical Team Lead of Verteville Energy Company Limited.",
  },
  {
    id: 7,
    name: "Dele Ibiyinka ",
    image: require("../images/dele.jpg"),
    email: "info@vertevilleenergy.com",
    position: "Project Manager",
    info1:
      "Engr. Oladele Ibiyinka (MBA, MNSE, CSSBB) is a power industry professional, with extensive knowledge, exposure and expertise that cut across diverse segment of the energy industry supply value chain.",
    info2:
      "A graduate of Electrical and Electronics Engineering from Obafemi Awolowo University, Ile-ife, Nigeria, an MBA in Finance from Ladoke Akintola University of Technology, Ogbomoso, and host of industry relevant professional training and development in over two decades of energy industry career.",
    info3:
      "He has been involved in the business development, O&amp;M services and contract performance obligation fulfilment of large, gas-turbine-based power project both in the pre-privatization, transition, and post-privatization market of the Nigeria Electricity Supply Industry (NESI). He has also been involved in both technical and commercial operational management in two Oil &amp; Gas firms (the TEL OML 49 and NPDC/Neconde JV OML 42) and possess robust understanding of Gas to Power business management processes.",
    info4:
      "Prior to working for Verteville Energy he was one of the pioneering team of the first IPP project in Nigeria by ENRON Corporation USA, which latter transition to AES Nigeria Barge Ltd (Member of AES Corp, Arlington, USA). He rose from trainee operations plant engineer to managerial level and served as an alternate member of board of directors before leaving AES Nig. Barge Ltd. He also led in the development stage of the 90MW on grid Power Project by Bresson Power Ltd and couple of Embedded and Captive Power transaction with Eligible customers.",
  },

  {
    id: 8,
    name: "Justice Eghwubare",
    image: require("../images/Justice.jpg"),
    email: "info@vertevilleenergy.com",
    position: "Plant manager",
    info1:
      "Eghwubare Justice (MNATE,COREN), has over twenty years work experience in power plant asset management.",
    info2:
      "He is a graduate of The Petroleum training institute Effurn-warri, Delta state , HND in electrical/electronic (instrumentation &control) engineering.",
    info3:
      "He has been involve in the operations and maintenance of gas and steam turbine electric power generating plant, with hand's on experience in planning, Operations, inspection, Electrical , Instrumentation & control maintenance.",
    info4:
      "Prior to joining  Verteville Energy company ltd, he worked with the first IPP project in Nigeria owned by ENRON Corporation USA, which latter transit  to AES Nigeria Barge Ltd . He rose through the ranks in AES Nigeria Barge Ltd from plant maintenance engineer to become the operations and maintenance engineer and  Assistant Team Lead –Electrical, instrumentation and control. He also worked at Negris Nig Ltd as electrical supervisor and NEPA Ogorode Thermal plant as Electrical operator.",
  },
];

export default teamData;
